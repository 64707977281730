import React from 'react';



import InventoryAGGrid from './InventorySTable.js'

function InventoryServerDataGrid({ componentProps }) {

    return (
    
        <>
          
            
            <InventoryAGGrid></InventoryAGGrid>
           
        </>
    )
}
export default InventoryServerDataGrid