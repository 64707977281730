import React, { Component } from 'react';
import { FormControl, FormGroup, Col, Row, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AgGridReact, } from 'ag-grid-react';
import { withRouter } from "react-router-dom"
import { throwErrorIfNon200Response, themeColors, generateErrorElementWithContactInfo } from "../../helpers/utils";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import PresentationComponent from "./SharedComponent/PresentationComponent"



class AssetsProfile extends Component {
    state = {
        show: false,
        modalGroupValueChange: false,
        groupValues: null,
        groupValuesData: null,
        groupName: '',
        groupValueSelected: null,
        userDescription: '',
        secondaryId: '',
        location: '',
        updatedGroupValues: null,
        isUpdatingProfile: false,
        enableUpdate: false,
        inventoryData: null,
        BasicInformationData: null,
        AssetInformationData: null,
        ReportDetailsData: null,
        isUpdateInProgress: false,
        enableSaveButton: false
    }
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
        document.title = "Advantix SmartSIM Portal -Inventory - Detail - Edit Inventory"

    }

    columnDefs = [
        {
            headerName: this.state.groupName,
            field: this.state.groupName,
            minWidth: 200,
            getQuickFilterText: function (params) {
                return params.value.name;
            }
        },];

    defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
    };

    onGridReady = (params) => {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };

    handleModal = (event) => {
        const groupname = event.target.dataset.groupname;
        const groupvalue = event.target.dataset.groupvalue;
        this.setState({ show: !this.state.show, groupName: groupname, groupNameSelcted: groupname, groupValueSelected: groupvalue },
            () => { this.fetchGroupValues() })
    }

    generateGroupValueLists = (groupName, data) => {
        let groupObject = {}
        const groupValuesTransform = []
        console.log(data)
        if (data.length > 0) {
            for (let index = 0; index < data.length; index++) {
                groupObject[groupName] = data[index]
                groupValuesTransform.push(groupObject)
                console.log(groupObject)
                groupObject = {}
            }
            return groupValuesTransform;
        }
    }

    handleNavigation = () => {
        this.props.history.goBack();
    }


    fetchGroupValues = () => {
        const simNumber = this.props.location.pathname.split("/")[2]
        fetch('api/SmartSIMInventoryController/GetGroupValueList', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            body: JSON.stringify({ "SIMNumber": simNumber, "Groupname": this.state.groupName })
        }).then(throwErrorIfNon200Response).then((response) => {
            return response.json().then((data) => {
                if (data) {
                    const groupValuesTransform = this.generateGroupValueLists(this.state.groupName, data)
                    this.setState({ groupValues: groupValuesTransform })
                }
                else {
                    this.setState({ isLoading: false })
                }
            });
        }).catch((ex) => {
            console.log(`error: ${ex}`);
            toast.error(generateErrorElementWithContactInfo("Error retrieving inventory details."));
        });
    }

    componentDidMount() {
        this.fetchInventoryData()
        this.fetchGroupValues()
    }


    onRowSelected = (e) => {
        let element = document.getElementById(this.state.groupNameSelcted + this.state.groupValueSelected)
        const groupValue = e.data[this.state.groupName]
        const updatedObject = {}
        updatedObject[this.state.groupNameSelcted] = groupValue
        this.state.groupValues[e.rowIndex] = updatedObject;
        element.textContent = e.data[this.state.groupName]
        let tempData = Object.assign(updatedObject, this.state.updatedGroupValues);
        this.setState({ updatedGroupValues: tempData, show: !this.state.show }, () => {
            this.enableSaveButton()
        })
    }
    openModal = () => {
        this.setState({
            show: !this.state.show,
        })

    }

    handleUpdateConfirmationModal = () => {
        this.setState({ enableUpdate: !this.state.enableUpdate })
    }

    enableSaveButton = () => {
        const UserDescription = this.state.BasicInformationData['User Description']
        const SecondaryId = this.state.BasicInformationData['Secondary ID']
        const Location = this.state.BasicInformationData['Location:']
        let enable = UserDescription != this.state.userDescription
            || SecondaryId != this.state.secondaryId || Location != this.state.location || this.state.updatedGroupValues != null
        this.setState({ enableSaveButton: enable })

    }


    updateAssetProfile = () => {
        this.setState({ isUpdatingProfile: true, enableUpdate: true, isUpdateInProgress: true })
        const simNumber = this.props.location.pathname.split("/")[2]
        const updateData = {
            "Username": this.state.userDescription,
            "SecondaryId": this.state.secondaryId,
            "SIMLocationState": this.state.location,
            "GroupValues": this.state.updatedGroupValues
        }
        fetch('api/SmartSIMInventoryController/UpdateAsset', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            body: JSON.stringify({
                "SIMNumber": simNumber, ...updateData,
            })

        }).then(throwErrorIfNon200Response).then((response) => {
            return response.json().then((data) => {
                if (data) {
                    const { statusType, message } = data
                    console.log(statusType)
                    if (statusType === "Fail") {
                        toast.error((message));
                        setTimeout(this.handleNavigation, 300)

                    }
                    else if (statusType === "Success") {
                        toast.success((message));
                        setTimeout(this.handleNavigation, 300)

                    }
                    this.setState({ isUpdatingProfile: false, isUpdateInProgress: false, enableUpdate: false })
                } else {
                    this.setState({ isUpdatingProfile: false, isUpdateInProgress: false, enableUpdate: false })
                }
            });
        }).catch((ex) => {
            this.setState({ isUpdatingProfile: false, isUpdateInProgress: false, enableUpdate: false })
            toast.error(generateErrorElementWithContactInfo("Error saving asset profile."));
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value }, () => {
            this.enableSaveButton()
        })
    }



    fetchInventoryData = () => {
        const simNumber = this.props.location.pathname.split("/")[2]
        this.setState({ isLoading: true })

        fetch('api/SmartSIMInventoryController/GetInventoryDetails', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            body: JSON.stringify({ "SIMNumber": simNumber })

        }).then(throwErrorIfNon200Response).then((response) => {
            return response.json().then((data) => {
                if (data) {
                    const { Provider, GroupValues, AssetProperties,
                        Username, RatePlanName, RatePlanCost, City, State,
                        SecondaryId, ServiceType } = data;
                    const basicTransformData = {
                        "User Description": Username,
                        "Secondary ID": SecondaryId,
                        "Provider": Provider,
                        "Location:": State ? State : '' + ', ' + City ? City : '',
                        'Service Type': ServiceType,
                        "Rate Plan": RatePlanName,
                        "Rate Plan Cost": RatePlanCost,
                    }
                    this.setState({
                        BasicInformationData: basicTransformData, AssetInformationData: AssetProperties, ReportDetailsData: GroupValues,
                        isLoading: false, location: State ? State : '' + ', ' + City ? City : '',
                        userDescription: Username, secondaryId: SecondaryId
                    })
                }
                else {
                    this.setState({ isLoading: false })
                }
            });
        }).catch((ex) => {
            this.setState({ isLoading: false });
            toast.error(generateErrorElementWithContactInfo("Error retrieving inventory details."));
        });
    }



    render() {
        const { isLoading } = this.state;
        if (this.gridRef.current) {
            const ColumnDefs = [{
                headerName: this.state.groupName,
                field: this.state.groupName,
                minWidth: 200,
                getQuickFilterText: function (params) {
                    return params.value.name;
                }
            }]
            this.gridRef.current.api.setColumnDefs(ColumnDefs)

        }
        const simNumber = this.props.location.pathname.split("/")[2]

        return (
            <div>
                <Modal isOpen={this.state.show} toggle={this.openModal}
                    centered>
                    <ModalHeader toggle={this.openModal}></ModalHeader>
                    <ModalBody>
                        <div className="ag-theme-alpine grid">
                            <AgGridReact
                                ref={this.gridRef}
                                onGridReady={this.onGridReady}
                                columnDefs={this.columnDefs}
                                defaultColDef={this.defaultColDef}

                                rowData={this.state.groupValues}
                                cacheQuickFilter={true}
                                onRowClicked={(e) => {
                                    this.onRowSelected(e)
                                }}

                                frameworkComponents={{
                                    customLoadingOverlay: () => <Spinner type="grow" />,
                                    customNoRowsOverlay: () => <div>No Group values</div>
                                }}
                                loadingOverlayComponent='customLoadingOverlay'
                                noRowsOverlayComponent='customNoRowsOverlay'
                                pagination
                                paginationAutoPageSize
                                enableCellTextSelection />
                        </div>
                    </ModalBody>
                </Modal>
                <Modal centered isOpen={this.state.enableUpdate} toggle={this.handleUpdateConfirmationModal}>
                    <ModalHeader toggle={this.handleUpdateConfirmationModal}></ModalHeader>
                    <ModalBody>
                        {this.state.isUpdateInProgress ? <div className="d-flex mb-3">
                            <div className="m-auto dot-flashing"></div>
                        </div> :
                            <div className="d-flex mb-3">
                                <div className="m-auto">
                                    <span>Please Confirm the changes before proceeding.</span>
                                </div>
                            </div>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" disabled={this.state.isUpdateInProgress} onClick={this.updateAssetProfile}>Confirm</Button>
                        <Button color="secondary" disabled={this.state.isUpdateInProgress} onClick={this.handleUpdateConfirmationModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <>
                    {isLoading ?
                        <div className="d-flex mt-11">
                            <Spinner className="m-auto" style={{ color: themeColors.primary }} type="grow" />
                        </div> :
                        <>
                            <h4 className='text-secondary mb-4'>Inventory - Detail- Edit Inventory</h4>
                            
                            <Row className="">
                                <Col  lg={12} className='ml-1'>
                                    <h4 className='mt-1 inventory-headline'>Asset Details</h4>
                                    <h5 className='text-secondary  mb-5 mt-4' style={{ whiteSpace: 'nowrap',  }}>SIM #: {simNumber} </h5>
                                </Col>
                                <Col lg={8} md={8}>
                                    <h4 className="live-data-header mb-2" >Basic Information</h4>
                                    <Row>
                                        <Col className="mb-3" md={5} lg={3} >
                                            <span className="live-data-title">User Description:</span>
                                        </Col>
                                        <Col lg={7} md={12} lg={7}>
                                            <FormGroup controlId="userDescription" onChange={this.handleChange} >
                                                <FormControl autoComplete="user decription" className="input-field" autoFocus type="text" defaultValue={this.state.BasicInformationData ? this.state.BasicInformationData['User Description'] : ''} />
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <Row className="mb-3">
                                        <Col className="" md={5} lg={3}>
                                            <span className="live-data-title">Secondary ID:</span>
                                        </Col>
                                        <Col lg={7} md={12} lg={7}>
                                            <FormGroup controlId="secondaryId" onChange={this.handleChange}  >
                                                <FormControl autoComplete="Secondary Id" className="input-field" autoFocus type="text" defaultValue={this.state.BasicInformationData ? this.state.BasicInformationData['Secondary ID'] : ''}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="" md={5} lg={3}>
                                            <span className="live-data-title" >Provider:</span>
                                        </Col>
                                        <Col lg={7} md={12} lg={7}>
                                            <span className="live-data-content">{this.state.BasicInformationData ? this.state.BasicInformationData['Provider'] : ''}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="" md={5} lg={3}>
                                            <span className="live-data-title">Location:</span>
                                        </Col>
                                        <Col md={12} lg={7}>
                                            <FormGroup controlId="location" onChange={this.handleChange} >
                                                <FormControl autoComplete="location" autoFocus className="input-field" type="text" defaultValue={this.state.BasicInformationData ? this.state.BasicInformationData['Location'] : ''}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="" md={5} lg={3}>
                                            <span className="live-data-title" >Service Type:</span>
                                        </Col>
                                        <Col md={12} lg={7}>
                                            <span className="live-data-content" >{this.state.BasicInformationData ? this.state.BasicInformationData['Service Type'] : ''}</span>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col className="" md={5} lg={3}>
                                            <span className="live-data-title" >Rate Plan:</span>
                                        </Col>
                                        <Col md={12} lg={7}>
                                            <span className="live-data-content" >{this.state.BasicInformationData ? this.state.BasicInformationData['Rate Plan'] : ''}</span>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={5} lg={3}>
                                            <span className="live-data-title" >Rate Plan Cost:</span>
                                        </Col>
                                        <Col md={12} lg={7}>
                                            <span className="live-data-content">${this.state.BasicInformationData ? this.state.BasicInformationData['Rate Plan Cost']?.substring(0, 5) : ''} </span>
                                        </Col>
                                    </Row>
                                </Col>

                                <Row className="mb-3 mt-3">
                                    <Col lg={12} sm={ 12} className="asset-container">
                                        
                                            {this.state.AssetInformationData ?
                                                <PresentationComponent
                                                    data={this.state.AssetInformationData}
                                                    Title="Assets Properties"
                                                    FirstColsm={5} FirstColmd={3} FirstCollg={3}
                                                    SecondCollg={2} SecondColmd={2} SecondColsm={5} />
                                                : <h5 className="text-center" style={{ color: themeColors.warning }}>No Data</h5>}
                                     

                                    </Col>
                                </Row >
                            </Row>
                            <Row>
                                <Col className="mb-4 mt-4" md={12} lg={8} sm={12}>
                                    <h4 className="live-data-header" >Reporting Details</h4>
                                    {this.state.ReportDetailsData ? Object.keys(this.state.ReportDetailsData).map((name, index) => {
                                        return (
                                            <div className="mb-4" key={index}>
                                                <Row>
                                                    <Col sm={12} md={3} lg={3} >
                                                        <span className="live-data-title">{name}:   </span>
                                                    </Col>
                                                    <Col data-id={this.state.ReportDetailsData[name] + name} sm={12} md={6} lg={6} className='mr-3' >
                                                        <span className="live-data-content" id={name + this.state.ReportDetailsData[name]}>{this.state.ReportDetailsData[name]}</span>
                                                    </Col>
                                                    <Col sm={5} md={2} lg={1}>
                                                        <Button variant="light" data-groupname={name} data-groupvalue={this.state.ReportDetailsData[name]} style={{ padding: 0 }}
                                                            onClick={this.handleModal} >Edit</Button></Col>
                                                </Row>
                                            </div>)
                                    }) : <h5 className="text-center" style={{ color: themeColors.warning }}>No Data</h5>}
                                </Col>
                            </Row>

                            <div className="d-flex mt-3" style={{ justifyContent: 'center' }}>
                                <Button className={this.state.enableSaveButton ? 'active-button mr-3' : 'mr-3'} disabled={!this.state.enableSaveButton} onClick={this.handleUpdateConfirmationModal} >Save Changes</Button>
                                <Button variant="light" onClick={this.handleNavigation} >Cancel</Button>

                            </div>
                        </>

                    }
                </>

            </div>
        )

    }
}

export default withRouter(AssetsProfile);