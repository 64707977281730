import React from 'react';
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-alpine.min.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccountBillingInformation from './AccountUserBillingInformation'
import ManageGroupValues from '../ManageReportGroups/ManageGroupValues'
import { UserInfo } from "./UserInformation.js";
import "./UserAccountmanagement.css";
function AccountUserManagement() {
   
    return (
        <Tabs
            defaultActiveKey="Billing-Information"
            id="justify-tab-example"
            className="mb-3"
            justify >
            <Tab eventKey="Billing-Information" title="Billing Information" >
               <AccountBillingInformation></AccountBillingInformation>
            </Tab>
            <Tab eventKey="User-Management" title="User Management">
                <UserInfo></UserInfo>
            </Tab>
            <Tab eventKey="Reporting-Groups" title="Reporting Groups">
                <ManageGroupValues></ManageGroupValues>
            </Tab>
        </Tabs>
    );

}

export default AccountUserManagement;