import { Link } from "react-router-dom"


const OrderDetaulsCellRender = (data) => {
    return <Link style={{ paddingLeft: '1rem' }} to={`/request/${data.data.orderId}/${data.data.InternalOrder}`}>{data.data.text}</Link>


}


export default OrderDetaulsCellRender