import React, { useRef} from 'react';
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from 'reactstrap';
import { themeColors } from "../../helpers/utils";


const UsageCellRenderSim = (simData, props) => {
    
    const ref = useRef(null)
    const lowRef = useRef(null)
    const zeroRef = useRef(null)   
    const threshold = parseInt(simData.props.threshold);
   

    const toolTipMessage = () => {
        if (parseInt(simData.simData['UsageQuantity']).toFixed() > threshold) {
            return (
                <UncontrolledTooltip
                    placement="top"
                    target={ ref}
                    trigger="hover">
                    <span>High Usage - above specified threshold ({threshold}MB)</span>
                </UncontrolledTooltip>)
        }
        else if (parseInt(simData.simData['UsageQuantity']).toFixed() <= threshold && parseInt(simData.simData['UsageQuantity']).toFixed()>0) {
            return <UncontrolledTooltip
                placement="top"
                target={ lowRef}
                trigger="hover">
                <span>Low Usage - below specified threshold ({threshold}MB)</span>

            </UncontrolledTooltip>
        }
        else if (parseInt(simData.simData['UsageQuantity']).toFixed() <= 0) {
            return <UncontrolledTooltip
                placement="top"
                target={ zeroRef}
                trigger="hover">
                <span>Zero Usage</span>
            </UncontrolledTooltip>
        }

    }

    const handleUsageData = () => {
        if (parseInt(simData.simData['UsageQuantity']).toFixed() > threshold) {
         return (
             <div ref={ref} style={{ backgroundColor: themeColors.danger, height: 'auto', width: '0.5rem', position: 'relative' }}>
                 <Link ref={ref} style={{ position: "relative", left: '1rem' }} to={`/inventorydetails-live-data/${simData.simData.PhoneNumber}`}>{simData.simData.PhoneNumber}</Link>
                </div>)
        }
        else if (parseInt(simData.simData['UsageQuantity']).toFixed() <= threshold && parseInt(simData.simData['UsageQuantity']).toFixed() > 0) {
         return (
             <div ref={lowRef} style={{ backgroundColor: themeColors.success, height: 'auto', width: '0.5rem', position: 'relative' }}>
                 <Link style={{ position: "relative", left: '1rem' }} to={`/inventorydetails-live-data/${simData.simData.PhoneNumber}`}>{simData.simData.PhoneNumber}</Link>
             </div>
            )
        }
        else if (parseInt(simData.simData['UsageQuantity']).toFixed() <= 0) {
         return (
             <div ref={zeroRef} style={{ backgroundColor: themeColors.warning, height: 'auto', width: '0.5rem', position: 'relative' }}>
                 <Link style={{ position: "relative", left: '1rem' }} to={`/inventorydetails-live-data/${simData.simData.PhoneNumber}`}>{simData.simData.PhoneNumber}</Link>
             </div>)
        }
    }



    return (
        <>
            <div style={{ position: "relative" }} >
                {simData.props.mode === 'summary' ? handleUsageData()
                    : <Link style={{ position: "relative", left: '1rem' }} to={`/inventorydetails-live-data/${simData.simData.PhoneNumber}`}>{simData.simData.PhoneNumber}</Link>}
                {simData.props.mode === 'summary' ?
                    <>{toolTipMessage()}
                    </> : null}
            </div>
            
            </>
            );
}

export default UsageCellRenderSim; 
