
import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Row, Col, Button, FormControl, FormGroup, } from 'react-bootstrap';
import { Card, CardBody, Spinner } from "reactstrap";
import { themeColors } from '../../helpers/utils';
import ActiveOrderCellRender from "./ActivateCellRender"
import ActivateOrderDetailsCellRender from "./ActivateOrderDetailsCellRender"
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
class ActivateOrder extends Component {
    constructor(props) {
        super(props);
        this.endcustomGrid = React.createRef();
    }


    state = {
        isCreateNewUser: false,
        EmailAddress: "",
        FirstName: "",
        LastName: "",
        EmployeeId: "",
        PhoneNumber: "",
        orderSummaries: [],
        ordrSummariesFormated:[],
        isCreatingNewUserInPogress: false,
        selectedRowToBeActivated: null,
        activeRatePlans: [],
        activateRateOrder: [],
        orderId: null,
        isModalOpen: false,
        currentActivateSIMInProgress: null,
        isActivationInProgress: false,
        activationError: null,
        loading: false
    }

    columnDefs = [
        {
            headerName: 'Order Number',
            headerTooltip: "Order Number",
            field: 'OrderNum',
            tooltipField: "Order Number",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 170,
            minWidth: 150,
        },
        {
            headerName: 'Account Name',
            headerTooltip: "Account Name",
            field: 'Company Name',
            cellStyle: { marginLeft: '16px' },
            minWidth: 140,
        },
        {
            headerName: 'Order Date',
            headerTooltip: 'Order Date',
            field: 'OrderDate',
            tooltipField: "Order Date",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 130,
        },
        {
            headerName: 'SIM Quantity',
            headerTooltip: 'SIM Quantity',
            field: 'SIMQuantity',
            tooltipField: "SIM Quantity",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 150,
            minWidth: 130,
        }, 
        {
            headerName: 'Order Status',
            headerTooltip: 'Order Status',
            field: 'Order Status',
            tooltipField: "Order Status",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 170,
            minWidth: 130,
        },

        {
            headerName: 'Order Details',
            headerTooltip: 'Order Details',
            field: 'Order Details',
            maxWidth: 170,
            minWidth: 130,
            cellStyle: { marginLeft: '16px' },
            //flex: 1,
            cellRenderer: (params) => {
                if (params.value !== undefined)
                    return <ActivateOrderDetailsCellRender data={params['data']['Order Details']}></ActivateOrderDetailsCellRender>;
                else
                    return "";
            }
        },
        {
            headerName: 'Activate',
            headerTooltip: 'Activate',
            field: 'Activate',
            maxWidth: 140,
            minWidth: 100,
            flex: 1,
            cellRenderer: (params) => {
                if (params.value !== undefined)
                    return <ActiveOrderCellRender data={params["data"]}></ActiveOrderCellRender>;
                else
                    return "";
            }
        },



    ];

    defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
    };

    onGridReady(params) {
        window.addEventListener('resize', () => {


            setTimeout(() => {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };

    openCreateUserModal = () => {
        this.setState({ isCreateNewUser: !this.state.isCreateNewUser })

    }
    componentDidMount() {
        this.fetchOrderSummary()
    }


  fetchOrderDetails = (orderId,ratePlans) => {
      let resData = {}
      this.setState({ isModalOpen: true, isActivationInProgress: true })
      this.setState({  }, () => {

          for (let index = 0; index < orderId.length; index++) {
              fetch("api/OrderController/OrderDetails", {
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                  },
                  method: "post",
                  body: JSON.stringify({
                      "sfAccountId": localStorage.getItem('salesforceId'),
                      "OrderId": orderId[index]['OrderId'].toString()

                  })
              }).then(response => {
                  return response.json().then(data => {
                      resData = data
                      if (data['statusType'] === 'Error') {
                          toast.error(data['errorMessage'])
                          return;
                      }
                      this.setState({ currentActivateSIMInProgress: index, isModalOpen: true, isActivationInProgress: true }, () => {
                          this.TransformData(data, ratePlans)
                      })



                  })
                 

              })

          }


      })
     

    

    }

    TransformData = (data, ratePlans) => {
        let reformatSimObject = {}
        const mapFormat = new Map()
        data.forEach((sim,indx) => {
            reformatSimObject = {
                "description": sim['PlanName'], //Refer to PlanName from Order details
                "SIMQuantity": "1",
                "unitCost": sim['PlanCost'], //Refer to PlanCost from Order details
                "SKU": sim['SKU'],
                "SIMNumber": sim['ItemDescription'],
                "replacedSIMNumber": null,
                "ItemStatus": sim['ItemStatus'],  // Refer to Order status from Order Summary endpoint
                "activationCost": null,
                "discountMRC": null,
                "discountMRCSurcharge": null,
                "discountactivationCost": null,
                "discountactivationSurcharge": null,
                "productCode": null
            }
            let RatePlans = []
            if (mapFormat.get(sim['PlanName'])) {
                RatePlans.push(reformatSimObject)
                const currentData = mapFormat.get(sim['PlanName'])
                const currentArray = [...currentData['PlanNames'], ...RatePlans]
                mapFormat.set(sim['PlanName'], { PlanNames: currentArray })

            }
            else {
                RatePlans = []
                RatePlans.push(reformatSimObject)
                mapFormat.set(sim['PlanName'], { PlanNames: RatePlans })
            }
        })
        let newArray = []
        let newObject = {}
     
        for (let [key, value] of mapFormat.entries()) {

            newObject = {
                Rateplans: value['PlanNames']
            }
            newArray.push(newObject)

        }
   
          this.setState({ activateRateOrder: [...this.state.activateRateOrder, ...newArray] }, () => {
              for (let index = 0; index < this.state.activateRateOrder.length ; index++) {
                      const payload =
                      {
                          "threadId": uuidv4(),
                          "createdDateTime": new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
                          "orderType": "Activation",
                          "provider": "Advantix",
                          "payload": {
                              "orderId": ratePlans[index]['OrderId'].toString(), // Refer to Order Id from Order Summary endpoint
                              "orderNumber": null,
                              "internalOrderId": ratePlans[index]['InternalOrderId'],  // Refer to Inernal Order Id from Order Summary endpoint
                              "accountSetupFee": null,
                              "replacementFee": null,
                              "overageRatePlanCost": null,
                              "suspendPlanCost": null,
                              "discountingPriceApproval": false,
                              "billingToken": null,
                              "termsAndConditionsVerId": null,
                              "primaryCarrier": null,
                              "account": null,
                              "charges": null,
                              "ratePlanOrderItems": this.state.activateRateOrder[index]['Rateplans'],
                              "paymentportal": null,
                              "PortalUser": {
                                  "PortalUserName": localStorage.getItem('PortalUsername'),
                                  "FirstName": localStorage.getItem('PortalUserFullname').split(" ")[0],
                                  "LastName": localStorage.getItem('PortalUserFullname').split(" ")[1],
                                  "Email": localStorage.getItem('PortalUsername'),
                                  "PortalAccountName": null,
                                  "PortalAccountId": null
                              },
                              "opportunity": null,
                              "requestSource": "tlm"
                          }
                      }
                      this.submitActivation(payload)

              }
         })

    }

    fetchOrderSummary = () => {
        this.setState({ loading: true });

        fetch("api/OrderController/OrderSummary", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId')
            })
        }).then(response => {
            return response.json(); 
        }).then(data => {
            this.setState({ orderSummaries: data }, () => {
                this.OrderSummaryRefomart(data); 
            });
            this.setState({ loading: false });
        }).catch(error => {
            console.error("Error fetching order summary:", error);
            this.setState({ loading: false });
        });
    }


    submitActivation = (payload) => {
           fetch("api/OrderController/ActivateOrders", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId'),
                ...payload

            })
        }).then(response => {
            return response.json().then(data => {
                if (data['payload']['errorCode'].length > 0) {
                    this.setState({ activationError: data['payload']['errorDescription'] })
                    this.setState({
                        isActivationInProgress: false,currentActivateSIMInProgress: null, }) 
                    return;
                }
                this.setState({
                    isActivationInProgress: false, currentActivateSIMInProgress: null,  }) 
                })



            })

        

    }


    activateOrder = () => {
        if (this.state.activeRatePlans.length < 0) return;
         this.fetchOrderDetails(this.state.activeRatePlans, this.state.activeRatePlans)
 }


    OrderSummaryRefomart = (data) => {
        let temp = []
        let NewObject = {}
        for (let index = 0; index < data.length; index++) {
            if (data[index]['OrderStatus'] == "Provisioned" || data[index]['OrderStatus'] == "ActivationError" || data[index]['OrderStatus'] == "PendingActivation") {
                
                NewObject = {
                    "OrderNum": data[index]['InternalOrderId'],
                    "Company Name": data[index]['CompanyName'],
                    "OrderDate": data[index]['OrderDate'],
                    //"Location": data[index]['ShippingAddress1'] + " " + data[index]['ShippingState'] + " " + data[index]['ShippingCity'],
                    "SIMQuantity": data[index]['SIMQuantity'],
                    "Order Status": data[index]['OrderStatus'],
                    "Order Details": {
                        text: "Order Details", orderId: data[index]["OrderId"], InternalOrder: data[index]["InternalOrderId"], threadId: data[index]["ThreadId"]
                    },
                    "Activate": {
                        setActivateRow: (row, rowActivate) => {
                            this.setState({ selectedRowToBeActivated: row }, () => {
                                let temp = []
                                if (rowActivate === false) {
                                    temp = this.state.activeRatePlans
                                    const findIndex = temp.findIndex(rate => rate['OrderId'] === row)
                                    temp.splice(findIndex, 1)

                                    return;
                                }
                                const selectedRow = this.state.orderSummaries.filter(rate => rate['OrderId'] === this.state.selectedRowToBeActivated)
                                temp.push(selectedRow[0])
                                this.setState({ activeRatePlans: [...this.state.activeRatePlans, ...temp] }, () => {
                                })
                            })
                        },
                        activate: false, activateIndex: index, OrderId: data[index]['OrderId']
                    }
                }
                temp.push(NewObject)
            }
            
        }
        this.setState({ ordrSummariesFormated: temp }, () => {

        })
    }

    render() {
        return (
            <>
                <Modal centered show={this.state.isModalOpen} onHide={() => { }}>
                    <Modal.Body>
                        {this.state.isActivationInProgress ?
                            <Row style={{ padding: "1.2rem" }}>
                                <Col sm={12} style={{ textAlign: "center", marginBottom: "1rem" }}>
                                    <span>  Activation is in processing</span>
                                </Col>
                                <Spinner style={{ margin: "auto", display: "flex", fontSize: "1.1rem" }} />
                            </Row>
                            :
                            this.state.activationError ?    <Row style={{ padding: "1rem" }}>
                                <Col sm={12} style={{ textAlign: "center" }}>
                                    <span>Activation has be Complete?</span>
                                </Col>
                                <Col style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                                    <Button onClick={() => { this.setState({ isModalOpen :false}) } } style={{ marginRight: "1rem" }} >Close</Button>

                                </Col>
                            </Row>:

                            <Row style={{ padding: "1rem" }}>
                                <Col sm={12} style={{ textAlign: "center" }}>
                                    <span>Activation is complete</span>
                                </Col>
                                <Col style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                                    <Button onClick={() => { this.setState({ isModalOpen :false}) } } style={{ marginRight: "1rem" }} >Close</Button>

                                </Col>
                            </Row>}
                    </Modal.Body>
                </Modal>
                <Row>
                    <Row>
                        <Col xl={12} className="mb-4">
                            <h4 className='text-secondary mb-2'>Orders Pending Activation</h4>
                        </Col>
                    </Row>
                    <Col md={2} lg={1} xl={1} xs={4} >
                        <Button style={{ backgroundColor: themeColors.secondary, border: "none" }} className=" return-link" ><span >Search</span></Button>
                    </Col>
                    <Col sm={8} xs={8} md={5} lg={4} xl={3} className="  manage-field-root ">
                        <FormGroup controlId="search" >
                            <FormControl placeholder="search" autoComplete="search" className="input-field input-manage-field" autoFocus type="text" />
                        </FormGroup>
                    </Col>
                    <Col className="d-flex" style={{ justifyContent: "end" }}>    <Button onClick={this.activateOrder} ><span>Activate</span></Button> </Col>
                </Row>
                <Row className="mt-2">
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="ag-theme-alpine grid">
                                    <AgGridReact
                                        ref={this.endcustomGrid}
                                        rowHeight={45}
                                        onGridReady={this.onGridReady}
                                        columnDefs={this.columnDefs}
                                        defaultColDef={this.defaultColDef}
                                        rowData={this.state.ordrSummariesFormated}
                                        frameworkComponents={{
                                            customLoadingOverlay: () => <Spinner type="grow" />,
                                            customNoRowsOverlay: () => <div>No Orders Pending Activation</div>,
                                            ActiveOrderCellRender: ActiveOrderCellRender,
                                            ActivateOrderDetailsCellRender: ActivateOrderDetailsCellRender
                                        }}
                                        loadingOverlayComponent='customLoadingOverlay'
                                        noRowsOverlayComponent='customNoRowsOverlay'
                                        pagination
                                        paginationAutoPageSize
                                        enableCellTextSelection
                                        tooltipShowDelay={0}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </>
        )
    }

}

export default ActivateOrder;