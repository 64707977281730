
import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Row, Col, Button, FormControl, FormGroup, } from 'react-bootstrap';
import { Card, CardBody, Spinner } from "reactstrap";
import { themeColors } from '../../helpers/utils';
import OrderDetailsCellRender from "./OrderDetailsCellRender"


class PendingRequestEndCustomer extends Component {
    constructor(props) {
        super(props);
        this.endcustomGrid = React.createRef();
    }
    state = {
        isCreateNewUser: false,
        EmailAddress: "",
        FirstName: "",
        LastName: "",
        EmployeeId: "",
        PhoneNumber: "",
        isCreatingNewUserInPogress: false,
        orderList: [],
        formateOderList: [],
        PortalUser: "",
        PortalUserEmail: "",
        loading: false
    }
    columnDefs = [
        {
            headerName: 'Order Number',
            headerTooltip: "Order Number",
            field: 'Order Number',
            tooltipField: "Order Number",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 170,
            minWidth: 140,
           },

        {
            headerName: 'Account Name',
            headerTooltip: "Account Name",
            field: 'Account Name',
            cellStyle: { marginLeft: '16px' },
            minWidth: 140,
         },
        {
            headerName: 'Order Date',
            headerTooltip: 'Order Date',
            field: 'OrderDate',
            tooltipField: "Order Date",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 125,
        },
        {
            headerName: 'SIM Quantity',
            headerTooltip: 'SIM Quantity',
            field: 'SIMQuantity',
            tooltipField: "SIM Quantity",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 140,
        },
        {
            headerName: 'Order Status',
            headerTooltip: 'Order Status',
            field: 'Order Status',
            tooltipField: "Order Status",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 170,
            minWidth: 130,
        },

        {
            headerName: 'Order Details',
            headerTooltip: 'Order Details',
            field: 'Order Details',
            maxWidth: 140,
            cellRenderer: (params) => {

                if (params.value !== undefined)
                    return <OrderDetailsCellRender data={params['data']['Order Details']}></OrderDetailsCellRender>;
                else
                    return "";
            }
            //flex: 1,
        },
    ];

    defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
    };
    onGridReady(params) {
        window.addEventListener('resize', () => {

       
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };
    openCreateUserModal = () => {
        this.setState({ isCreateNewUser: !this.state.isCreateNewUser })

    }

    fetchOrderSummary = () => {
        this.setState({ loading: true });

        fetch("api/OrderController/OrderSummary", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId')
            })
        }).then(response => {
            return response.json();
        }).then(data => {
            this.setState({ orderList: data }, () => {
                this.formateOrderList(data);
            });
            this.setState({ loading: false });
        }).catch(error => {
            console.error("Error fetching order summary:", error);
            this.setState({ loading: false });
        });
    }

    componentDidMount() {
        this.fetchOrderSummary()
    }

    formateOrderList = (data) => {
        let forMateObject = {}
        let newFormateArray = []
        for (let index = 0; index <= data.length - 1; index++) {
            if (data[index]['OrderStatus'] == "DepositPaid" || data[index]['OrderStatus'] == "Invoice" || data[index]['OrderStatus'] == "ProvisionError" || data[index]['OrderStatus'] == "PendingProvisioning" || data[index]['OrderStatus'] == "PendingApproval") {
                forMateObject = {
                    "Order Number": data[index]['InternalOrderId'],
                    "Account Name": data[index]['CompanyName'],
                    "ThreadId": data[index]['ThreadId'],
                    "OrderDate": data[index]['OrderDate'],
                    // "Location": data[index]['ShippingState'] + " " + data[index]['ShippingCity'],
                    "SIMQuantity": data[index]['SIMQuantity'],
                    "Order Status": data[index]['OrderStatus'],
                    "Order Details": { text: "Order Details", orderId: data[index]["OrderId"], InternalOrder: data[index]["InternalOrderId"], threadId: data[index]["ThreadId"] },
                }
                newFormateArray.push(forMateObject)

            }
        }
        this.setState({ formateOderList: newFormateArray })


    }
    render() {


        return (
            <>

                <Row>
                    <Row>
                        <Col xl={12} className="mb-4">
                            <h5 className='text-secondary mb-2'>Orders Pending Provisioning</h5>
                        </Col>
                    </Row>

                  
                    <Col md={2} lg={1} xl={1} xs={4} >
                        <Button style={{ backgroundColor: themeColors.secondary, border: "none" }} className="   return-link" onClick={() => { }}><span >Search</span></Button>
                    </Col>
                    <Col sm={8} xs={8} md={5} lg={4} xl={3} className="  manage-field-root">
                        <FormGroup controlId="search" >
                            <FormControl placeholder="search" autoComplete="search" className="input-field input-manage-field" autoFocus type="text" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="ag-theme-alpine grid">
                                    <AgGridReact
                                        ref={this.endcustomGrid}
                                        rowHeight={45}
                                        onGridReady={this.onGridReady}
                                        columnDefs={this.columnDefs}
                                        defaultColDef={this.defaultColDef}
                                        rowData={this.state.formateOderList}
                                        frameworkComponents={{
                                            customLoadingOverlay: () => <Spinner type="grow" />,
                                            customNoRowsOverlay: () => <div>No Orders Pending Provisioning</div>,
                                            OrderDetailsCellRender: OrderDetailsCellRender
                                        }}
                                        loadingOverlayComponent='customLoadingOverlay'
                                        noRowsOverlayComponent='customNoRowsOverlay'
                                        pagination
                                        paginationAutoPageSize
                                        enableCellTextSelection
                                        tooltipShowDelay={0}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </>
        )
    }

}

export default PendingRequestEndCustomer;