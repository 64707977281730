import { Button, Form } from 'react-bootstrap';
import { themeColors } from "../../helpers/utils";
import { useState, useEffect } from "react"




const ActiveOrderCellRender = (data) => {
    const[isAcivtate, setActivate]= useState(false)

    return (
        <div className="d-flex" style={{ justifyContent: "center", marginTop: "0.5rem" }}>
            <Form.Check
                type='checkbox'
                onClick={(e) => {
                    setActivate((prev) => {
                        const activate = !prev;
                        
                        data.data.Activate.setActivateRow(data.data['Order Details'].orderId,activate)
                        return !prev
                    })
                }}
                data-value={isAcivtate }
                defaultChecked={isAcivtate}
                name="activate" />
        </div>
    )
}
export default ActiveOrderCellRender